import sources from '@content/sources.json';
import { Content } from '../types';

interface sourcesType {
  Name: string;
  Correct_Text: Content;
  Sources: Content | null;
}

export default sources as sourcesType[];
