import { graphql } from 'gatsby';
import checkFeatureFlag from '@utils/checkFeatureFlag';
import {
  EndPage as EndPageV1,
} from '../components/end-page-v1';
import {
  EndPage as EndPageV2,
} from '../components/end-page-v2';

const isModulesEnabled = checkFeatureFlag('MODULES') === 'FLAG_VALID';
const EndPage = isModulesEnabled ? EndPageV2 : EndPageV1;

export const query = graphql`
  query EndPage {
    endPageSymbols: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "End Text" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }

    endPageBgSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Claim Background Image" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
  }
`;

export default EndPage;
