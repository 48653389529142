import React, { useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import { useAppDispatch, useAppSelector } from '@hooks/redux-hooks';
import { Para, Button, Flex } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';
import Layout from '@containers/Layout';
import useTranslation from '@hooks/useTranslation';
import { actions as routeActions } from '@slices/routeSlice';
import BodyWrapper from '@components/BodyWrapper';
import { gameHasFinished, setHeaderType } from '@slices/gameStateSlice';
import gameConfig from '@content/gameconfig';
import { logItem } from '@slices/loggingSlice';
import useNavigateLog from '@hooks/useNavigateLog';
import SourcesTrayButton from '@containers/SourcesTrayButton';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import { WINDOW_HASH } from '@sharedConstants';
import { selectCurrentModule } from '../module/profile-slice';
import { History } from '../module/history';
import { Score } from './score';
import { AllowedScores, Module } from '../module/module';
import { ModuleOption } from './module-option';

const ContinueButton = styled(Button)(
  css({
    alignSelf: 'center',
  }),
);

const CenterContentWrapper = styled(Flex)({
  isolation: 'isolate',
  mt: 2,
  flex: 0.5,
  flexDirection: 'column',
});

const BottomContentWrapper = styled(Flex)({
  isolation: 'isolate',
  flex: 1,
  flexDirection: 'column',
  alignSelf: 'center',
});

export const EndPage = ({ data }: PageProps<Queries.EndPageQuery>) => {
  const currentModule = useAppSelector(selectCurrentModule);
  const { userId } = useAppSelector(state => state.logging);
  const { claimsAnsweredCorrectly, totalClaims } = useAppSelector(state => state.levelling);
  useNavigateLog({ questionName: 'end' });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [moduleScore, setModuleScore] = useState<AllowedScores>(0);

  useEffect(() => {
    setModuleScore(Module.calculateScore(claimsAnsweredCorrectly, totalClaims));
  }, [claimsAnsweredCorrectly, totalClaims]);

  useEffect(() => {
    if (currentModule && userId) {
      const history = History.getHistory(userId);
      const result = History.createRecord(history, { module: currentModule, correct: claimsAnsweredCorrectly, totalClaims });
      if (!result.isSuccess) {
        console.log('History createRecord failed', result);
      }
    } else {
      console.error('EndPage: currentModule or userId not found');
    }
  }, []);

  useEffect(() => {
    dispatch(
      routeActions.setCurrentRoute({ compareAgainst: 'end', findBy: 'name' }),
    );
    dispatch(setHeaderType({ headerType: 'shareable' }));
    dispatch(gameHasFinished());
  }, [dispatch]);

  return (
    <Layout>
      <BodyWrapper overflow="hidden" backgroundColor="transparent">
        <Flex flex={1} />
        <CenterContentWrapper>
          <Para mt={3} mb={2} variant="p1">
            <CustomMdxRenderer>{t(`End Text ${moduleScore}-Stars`, true)}</CustomMdxRenderer>
          </Para>
          <Flex justifyContent="center" mb={2}>
            <Score points={moduleScore} />
          </Flex>
          <Para>
            <CustomMdxRenderer>
              {t('End Screen Correct', true)}
            </CustomMdxRenderer>
          </Para>
          {gameConfig.Enable_Sources_Modal && (
            <Flex alignSelf="center" marginTop={25}>
              <SourcesTrayButton />
            </Flex>
          )}
        </CenterContentWrapper>
        <BottomContentWrapper>
          <ModuleOption replayMode module={currentModule} />
          <ContinueButton
            data-cy="continue"
            style={{ width: '250px' }}
            onPress={() => {
              dispatch(
                logItem({
                  collection_name: 'events',
                  event_type: 'click',
                  location: window?.location?.pathname,
                  target: 'continue-button',
                }),
              );
              navigate(`/${WINDOW_HASH}`, {
                state: { cameFromEnd: true },
              });
            }}
          >
            <Para>{t('Continue')}</Para>
          </ContinueButton>
        </BottomContentWrapper>
      </BodyWrapper>
    </Layout >
  );
};
